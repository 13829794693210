import { getHslAnimationFromHsl, getRangeValue, itemFromSingleOrMultiple, randomInRange, rangeColorToHsl, } from "tsparticles-engine";
function updateColorValue(delta, value, valueAnimation, max, decrease) {
    var _a, _b;
    const colorValue = value;
    if (!colorValue ||
        !colorValue.enable ||
        (colorValue.loops !== undefined &&
            colorValue.maxLoops !== undefined &&
            colorValue.maxLoops > 0 &&
            colorValue.loops >= colorValue.maxLoops)) {
        return;
    }
    const offset = randomInRange(valueAnimation.offset), velocity = ((_a = value.velocity) !== null && _a !== void 0 ? _a : 0) * delta.factor + offset * 3.6, decay = (_b = value.decay) !== null && _b !== void 0 ? _b : 1;
    if (!decrease || colorValue.status === "increasing") {
        colorValue.value += velocity;
        if (colorValue.value > max) {
            if (!colorValue.loops) {
                colorValue.loops = 0;
            }
            colorValue.loops++;
            if (decrease) {
                colorValue.status = "decreasing";
                colorValue.value -= colorValue.value % max;
            }
        }
    }
    else {
        colorValue.value -= velocity;
        if (colorValue.value < 0) {
            if (!colorValue.loops) {
                colorValue.loops = 0;
            }
            colorValue.loops++;
            colorValue.status = "increasing";
            colorValue.value += colorValue.value;
        }
    }
    if (colorValue.velocity && decay !== 1) {
        colorValue.velocity *= decay;
    }
    if (colorValue.value > max) {
        colorValue.value %= max;
    }
}
function updateStrokeColor(particle, delta) {
    if (!particle.strokeColor || !particle.strokeAnimation) {
        return;
    }
    const h = particle.strokeColor.h;
    if (h) {
        updateColorValue(delta, h, particle.strokeAnimation.h, 360, false);
    }
    const s = particle.strokeColor.s;
    if (s) {
        updateColorValue(delta, s, particle.strokeAnimation.s, 100, true);
    }
    const l = particle.strokeColor.l;
    if (l) {
        updateColorValue(delta, l, particle.strokeAnimation.l, 100, true);
    }
}
export class StrokeColorUpdater {
    constructor(container) {
        this.container = container;
    }
    init(particle) {
        var _a, _b, _c;
        const container = this.container;
        const stroke = itemFromSingleOrMultiple(particle.options.stroke, particle.id, particle.options.reduceDuplicates);
        particle.strokeWidth = getRangeValue(stroke.width) * container.retina.pixelRatio;
        particle.strokeOpacity = getRangeValue((_a = stroke.opacity) !== null && _a !== void 0 ? _a : 1);
        particle.strokeAnimation = (_b = stroke.color) === null || _b === void 0 ? void 0 : _b.animation;
        const strokeHslColor = (_c = rangeColorToHsl(stroke.color)) !== null && _c !== void 0 ? _c : particle.getFillColor();
        if (strokeHslColor) {
            particle.strokeColor = getHslAnimationFromHsl(strokeHslColor, particle.strokeAnimation, container.retina.reduceFactor);
        }
    }
    isEnabled(particle) {
        var _a, _b, _c;
        const color = particle.strokeAnimation;
        return (!particle.destroyed &&
            !particle.spawning &&
            !!color &&
            ((((_a = particle.strokeColor) === null || _a === void 0 ? void 0 : _a.h.value) !== undefined && particle.strokeColor.h.enable) ||
                (((_b = particle.strokeColor) === null || _b === void 0 ? void 0 : _b.s.value) !== undefined && particle.strokeColor.s.enable) ||
                (((_c = particle.strokeColor) === null || _c === void 0 ? void 0 : _c.l.value) !== undefined && particle.strokeColor.l.enable)));
    }
    update(particle, delta) {
        if (!this.isEnabled(particle)) {
            return;
        }
        updateStrokeColor(particle, delta);
    }
}
